export const DataItem = [
  {
    id: "KML12",
    name: "Burger Italienne",
    category: "répas",
    status: "Disponible",
    action: "éditer",
    stock: 12,
  },
  {
    id: "KBCD24",
    name: "Sandwich Marocain",
    category: "répas",
    status: "Non Disponible",
    action: "éditer",
    stock: 12,
  },
  {
    id: "ER0124",
    name: "Pizza Italienne",
    category: "répas",
    status: "Disponible",
    action: "éditer",
    stock: 12,
  },
  {
    id: "FR0124",
    name: "Pizza Italienne",
    category: "répas",
    status: "Disponible",
    action: "éditer",
    stock: 12,
  },
  {
    id: "FS0124",
    name: "Burger Italienne",
    category: "répas",
    status: "Disponible",
    action: "éditer",
    stock: 12,
  },
  {
    id: "KBEEZZ24",
    name: "Sandwich Marocain",
    category: "répas",
    status: "Non Disponible",
    action: "éditer",
    stock: 12,
  },
  {
    id: "KBSDD24",
    name: "Pizza Italienne",
    category: "répas",
    status: "Disponible",
    action: "éditer",
    stock: 12,
  },
  {
    id: "KB0Z4",
    name: "Pizza Italienne",
    category: "répas",
    status: "Disponible",
    action: "éditer",
    stock: 12,
  },
  {
    id: "KB0124",
    name: "Burger Italienne",
    category: "répas",
    status: "Disponible",
    action: "éditer",
    stock: 12,
  },
  {
    id: "KB0124",
    name: "Sandwich Marocain",
    category: "répas",
    status: "Non Disponible",
    action: "éditer",
    stock: 12,
  },
  {
    id: "KB0124",
    name: "Pizza Italienne",
    category: "répas",
    status: "Disponible",
    action: "éditer",
    stock: 12,
  },
  {
    id: "KB0124",
    name: "Pizza Italienne",
    category: "répas",
    status: "Disponible",
    action: "éditer",
    stock: 12,
  },
  {
    id: "KB0124",
    name: "Burger Italienne",
    category: "répas",
    status: "Disponible",
    action: "éditer",
    stock: 12,
  },
  {
    id: "KB0124",
    name: "Sandwich Marocain",
    category: "répas",
    status: "Non Disponible",
    action: "éditer",
    stock: 12,
  },
  {
    id: "KB0124",
    name: "Pizza Italienne",
    category: "répas",
    status: "Disponible",
    action: "éditer",
    stock: 12,
  },
  {
    id: "KB0124",
    name: "Pizza Italienne",
    category: "répas",
    status: "Disponible",
    action: "éditer",
    stock: 12,
  },
];
