import React, { useState } from "react";
import { IoFastFoodSharp } from "react-icons/io5";
import { ButtonPrimary } from "../components/Molecules/button/button";
import { MenuItemHeader, MenuItemList } from "../components/Molecules/list/menuItem";
import "../styles/menu.css";
import MenuDetails from "../screens/menuDetail";

import { BrowserRouter, Link, useRouteMatch, Route, Switch, useHistory } from "react-router-dom";

import { DataItem } from "../server/db";
import AddItem from "./addItem";

export default function Menu() {
  const { path, url } = useRouteMatch();
  return (
    <BrowserRouter>
      <Switch>
        <Route path={path} exact>
          <Home></Home>
        </Route>
        <Route path={`${path}/edit/add`} exact>
          <AddItem></AddItem>
        </Route>
        <Route path={`${path}/:item`} exact>
          <MenuDetails></MenuDetails>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

function Home() {
  const [tab, setTab] = useState(DataItem);
  const { path, url } = useRouteMatch();
  const History = useHistory();
  return (
    <div className="containerMenu">
      <div className="header_container">
        <div className="title_container">
          <IoFastFoodSharp size={50} color="white" className="menu_icon"></IoFastFoodSharp>
          <div>
            <p className="menu_text">Menus</p>
            <p className="menu_desc"> Ajouter, modifier ou supprimer vos menus</p>
          </div>
        </div>
        <ButtonPrimary
          onClick={() => {
            History.push(`${url}/edit/add`);
          }}
        ></ButtonPrimary>
      </div>
      <MenuItemHeader></MenuItemHeader>
      <div className="menuItem_container">
        {tab.map((item, index) => (
          <Link to={`${url}/${item.id}`} style={{ textDecoration: "none" }}>
            <MenuItemList
              id={item.id}
              name={item.name}
              category={item.category}
              status={item.status}
              stock={item.stock}
              onClick={(e) => console.log(e.target)}
            ></MenuItemList>
          </Link>
        ))}
      </div>
    </div>
  );
}
