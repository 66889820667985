import React from "react";
import { BrowserRouter as Router, Route, Switch, useRouteMatch, HashRouter } from "react-router-dom";

import Accueil from "../screens/accueil";
import Orders from "../screens/orders";
import Menu from "../screens/menu";
import Delivery from "../screens/delivery";
import Sidebar from "../components/organisms/sidebar";
import Header from "../components/organisms/header";
import MenuDetails from "../screens/menuDetail";
import NotFound from "../screens/404";
//import Menu from "../routes/menu.route.JS";

export default function DashboardRoutes() {
  const { path, url } = useRouteMatch();
  return (
    <Router>
      <Sidebar></Sidebar>

      <div
        style={{
          width: "83%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Route path={path} exact component={Accueil}></Route>
        <Route path={`/commandes`} component={Orders}></Route>
        <Route path={`/menus`}>
          <Menu></Menu>
        </Route>
        <Route path={`/livraison`} exact component={Delivery}></Route>
      </div>
    </Router>
  );
}
