import React from "react";
import { MdDelete, MdStar } from "react-icons/md";
import "./menuItem.css";
import itemImage from "../../../assets/login.jpg";

export function MenuItemHeader() {
  return (
    <div className="itemHeader">
      <div style={{ flex: 1 }}>ID menu</div>
      <div style={{ flex: 3 }}>Nom</div>
      <div style={{ flex: 2 }}>Catégories</div>
      <div style={{ flex: 2 }}>Avis des clients</div>
      <div style={{ flex: 2 }}>Status</div>
      <div style={{ flex: 1 }}>Stock</div>
      <div style={{ flex: 3 }}>image</div>
    </div>
  );
}

export function MenuItemList(props) {
  return (
    <div className="itemList" onClick={props.onClick}>
      <div style={{ flex: 1 }}>{props.id}</div>
      <div style={{ flex: 3 }}>{props.name}</div>
      <div style={{ flex: 2 }}>{props.category}</div>
      <div style={{ flex: 2 }}>
        <MdStar color="#ffc107" size={20}></MdStar>
        <MdStar color="#ffc107" size={22}></MdStar>
        <MdStar color="#ffc107" size={24}></MdStar>
        <MdStar color="#444452" size={26}></MdStar>
        <MdStar color="#444452" size={28}></MdStar>
      </div>
      <div style={{ flex: 2 }}>{props.status}</div>
      <div style={{ flex: 1 }}>{props.stock}</div>
      <div style={{ flex: 3, display: "flex" }}>
        <img src={itemImage} style={{ width: 30, height: 30, marginRight: 10 }}></img>
        <img src={itemImage} style={{ width: 30, height: 30, marginRight: 10 }}></img>
        <img src={itemImage} style={{ width: 30, height: 30, marginRight: 10 }}></img>
      </div>
    </div>
  );
}
