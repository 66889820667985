import React from "react";
import DashboardRoutes from "../routes/dashboard.route";

import { BrowserRouter as Router, Route } from "react-router-dom";

export default function Dashboard() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        backgroundColor: "#263238",
      }}
    >
      <DashboardRoutes></DashboardRoutes>
    </div>
  );
}
