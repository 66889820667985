import React, { useState } from "react";
import "../styles/login.css";

import TextInput from "../components/Molecules/inputs/textInput";
import { ButtonLogin } from "../components/Molecules/button/button";
import { auth } from "../server/login/authentificate";
import AuthStatus from "../components/Molecules/list/auth";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: false,
      identifiant: null,
      password: null,
      loginStatus: undefined,
      color: undefined,
    };
  }

  _handleLoginRequest() {
    if (this.state.spinner === false) {
      this.setState({ spinner: true });
      auth(this.state.identifiant, this.state.password).then((data) => {
        this.setState({ spinner: false });
        if (data.status == "success") {
          this.setState({ loginStatus: "Vous êtes connecté avec succès" });
          this.setState({ color: "green" });

          window.open("/", "_self");
        } else {
          this.setState({
            loginStatus: "Mot de passe ou identifiant incorrect",
          });
          this.setState({ color: "orange" });
        }
      });
    } else {
      this.setState({ spinner: false });
    }
  }
  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "#eee",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="card">
          <p
            style={{
              fontSize: 40,
              fontWeight: "400",
              fontFamily: "roboto",
            }}
          >
            Connexion
          </p>
          <TextInput
            placeholder="ID restaurant"
            type="text"
            onChange={(e) => this.setState({ identifiant: e.target.value })}
          ></TextInput>
          <TextInput
            placeholder="Mot de passe"
            type="password"
            onChange={(e) => this.setState({ password: e.target.value })}
          ></TextInput>

          <ButtonLogin
            onPress={() => {
              this._handleLoginRequest();
            }}
            spinner={this.state.spinner}
          >
            Connecter
          </ButtonLogin>
          <AuthStatus
            title={this.state.loginStatus || "Vous n'êtes pas connecté"}
            color={this.state.color || "gray"}
          ></AuthStatus>
        </div>
        <div className="hero">
          <div className="hero-container">
            <p style={{ fontSize: 100, color: "white", fontWeight: "bold" }}>
              Foodizo
            </p>
          </div>
        </div>
      </div>
    );
  }
}
