import React from "react";
import { InputWithLabel } from "../components/Molecules/inputs/input";
import TextInput from "../components/Molecules/inputs/textInput";
export default function addItem() {
  return (
    <div>
      <InputWithLabel label="Nom"></InputWithLabel>
    </div>
  );
}
