export const auth = async (username, password) => {
  console.log(username);
  let response = await fetch("http://localhost:8082/api/v1/auth", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: "username=" + username + "&password=" + password,
  });

  let data = await response.json();
  console.log(username + " " + password);
  return data;
};
