import React from "react";
import "./input.css";

export default function TextInput(props) {
  return (
    <input
      placeholder={props.placeholder}
      type={props.type}
      className="input"
      onChange={props.onChange}
    ></input>
  );
}
