import React from "react";
import { MdHome } from "react-icons/md";
import { IoMdCart } from "react-icons/io";
import { IoFastFood } from "react-icons/io5";
import { RiMotorbikeFill } from "react-icons/ri";
import { BiLogOut } from "react-icons/bi";

import { Link, useRouteMatch, NavLink } from "react-router-dom";
import "./item.css";

export function Home(props) {
  return (
    <NavLink exact className="item" activeClassName="activeItem" {...props}>
      <MdHome size={25} style={{ marginRight: 20 }}></MdHome>
      <p style={{ marginTop: 20, fontSize: 17 }}>Accueil</p>
    </NavLink>
  );
}

export function Orders(props) {
  return (
    <NavLink exact className="item" activeClassName="activeItem" {...props}>
      <IoMdCart size={25} style={{ marginRight: 20 }}></IoMdCart>
      <p style={{ marginTop: 20, fontSize: 17 }}>Les commandes</p>
    </NavLink>
  );
}

export function Menus(props) {
  const { path, url } = useRouteMatch();
  return (
    <NavLink exact className="item" activeClassName="activeItem" {...props}>
      <IoFastFood size={25} style={{ marginRight: 20 }}></IoFastFood>
      <p style={{ marginTop: 20, fontSize: 17 }}>Les menus</p>
    </NavLink>
  );
}

export function Delivery(props) {
  return (
    <NavLink exact className="item" activeClassName="activeItem" {...props}>
      <RiMotorbikeFill size={25} style={{ marginRight: 20 }}></RiMotorbikeFill>
      <p style={{ marginTop: 20, fontSize: 17 }}>Livreurs</p>
    </NavLink>
  );
}

export function LogOut(props) {
  return (
    <div className="logOut" {...props}>
      <BiLogOut size={25} style={{ marginRight: 20 }}></BiLogOut>
      <p style={{ marginTop: 20, fontSize: 17 }}>Se deconnecter</p>
    </div>
  );
}
