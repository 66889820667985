import React from "react";
import { BrowserRouter as Router, Route, useRouteMatch } from "react-router-dom";

import Dashboard from "../screens/dashboard";
import Login from "../screens/login";

export default function Index() {
  return (
    <div>
      <Router>
        <Route path={`login`} exact component={Login}></Route>
        <Route path="/" component={Dashboard}></Route>
      </Router>
    </div>
  );
}
