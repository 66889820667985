import React from "react";
import "./header.css";
import Images from "../../assets/login.jpg";

import { TiThMenuOutline } from "react-icons/ti";

import Avatar from "@material-ui/core/Avatar";

export default class Header extends React.Component {
  render() {
    return (
      <div className="header">
        <di>
          <TiThMenuOutline
            size={25}
            color="white"
            cursor="pointer"
          ></TiThMenuOutline>
        </di>
        <div style={{ display: "flex" }}>
          <Avatar src={Images} style={{ marginRight: 10 }} />
          <p
            style={{
              fontSize: 17,
              color: "white",
              fontFamily: "roboto",
              marginTop: 5,
            }}
          >
            King Burger Bamako
          </p>
        </div>
      </div>
    );
  }
}
