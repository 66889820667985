import React, { useState } from "react";
import "./input.css";
import { BiRename } from "react-icons/bi";

export function InputWithLabel(props) {
  const [value, setValue] = useState(0);
  let slice = (event) => {
    event.target.value = event.target.value.slice(0, event.target.maxLength);
  };

  let numFormat = (event) => {
    let number = event.target.value.replace(/\s+/g, "").trim();
    let length = number.length;

    let arr = [];
    let result = "";

    for (let i = 0; i <= length - 1; i += 3) {
      let slice = number.slice(i, i + 3);
      arr.push(slice, " ");
    }

    arr.pop();
    console.log(arr);
    arr.map((i) => {
      result += i;
    });
    event.target.value = result;
  };
  return (
    <div>
      <p style={{ color: "white", fontWeight: 600, fontSize: 20 }}>{props.label}</p>
      <input
        placeholder={props.placeholder}
        className="inputWithLabel"
        {...props}
        onInput={() => {
          //slice(event);
          numFormat(event);
        }}
      ></input>
    </div>
  );
}
