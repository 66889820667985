import React from "react";

export default function Logo() {
  return (
    <div
      style={{
        color: "white",
        fontSize: 30,
        fontFamily: "roboto",
        fontWeight: "bold",
      }}
    >
      Foodizo
    </div>
  );
}
