import React from "react";
import { BsFillLockFill, BsCheck } from "react-icons/bs";

export default function authStatus(props) {
  return (
    <div
      style={{
        position: "absolute",
        bottom: 20,
        display: "flex",
        alignItems: "center",
        height: 50,
        fontFamily: "inter",
        color: props.color,
      }}
    >
      <BsFillLockFill
        style={{ marginRight: 15, marginTop: -15 }}
        size={25}
      ></BsFillLockFill>

      <p>{props.title}</p>
    </div>
  );
}
