import React, { useState } from "react";
import "./button.css";
import { Spinner } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { MdAddCircle } from "react-icons/md";

export function ButtonLogin(props) {
  const [spinner, setSpinner] = useState(false);

  return (
    <div className="button" onClick={props.onPress}>
      {props.spinner == false ? "Connecter" : <Spinner animation="border" />}
    </div>
  );
}

export function ButtonPrimary(props) {
  return (
    <Button variant="contained" color="primary" className="add-menu" startIcon={<MdAddCircle></MdAddCircle>} {...props}>
      Ajouter du menu
    </Button>
  );
}
