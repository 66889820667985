import Index from "./routes/index.route";
function App() {
  return (
    <div>
      <Index></Index>
    </div>
  );
}

export default App;
