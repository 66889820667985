import React from "react";
import { Home, Menus, Orders, Delivery, LogOut } from "../Molecules/list/item";
import SideBar from "../Molecules/list/item";
import Logo from "../Molecules/logo";
import "./sidebar.css";
import { useRouteMatch, Link } from "react-router-dom";

export default function Sidebar() {
  let { path, url } = useRouteMatch();
  return (
    <div style={{ backgroundColor: "#263238", height: "100vh", width: "17%", padding: 10 }}>
      <div className="LogoContainer">
        <Logo></Logo>
      </div>

      <Home to="/"></Home>
      <Orders to="/commandes"></Orders>
      <Menus to={`/menus`}></Menus>
      <Delivery to={`/livraison`}></Delivery>
      <LogOut></LogOut>
    </div>
  );
}
